@use "sass:math";
@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.Toaster-container {
  z-index: 1000;
  position: fixed;
  max-width: 470px;
}

.Toaster-container--bottomLeft {
  bottom: 5vh;
  left: 5vh;
}

.Toaster-container--topLeft {
  left: 5vh;
  top: 5vh;
}

.Toaster-container--mobile {
  @include lt(small) {
    left: math.div($spacing--medium, 3);
    right: math.div($spacing--medium, 3);
    top: 2px;
    bottom: auto;
    z-index: 2000;
  }
}

.Toaster-list {
  display: flex;
  flex-direction: column;
}

.Toast-Container {
  margin-top: $spacing--micro;
}

@include lt(small) {
  .Toast-Container-enter {
    opacity: 0;
    transform: translateY(-100%);
  }

  .Toast-Container-enter-active {
    transition:
      transform 250ms ease-out,
      opacity 250ms ease-in;
    transform: translateY(0%);
    opacity: 1;
  }

  .Toast-Container-exit {
    opacity: 1;
    max-height: 75px;
  }

  .Toast-Container-exit-active {
    opacity: 0;
    transition:
      opacity 250ms ease-out,
      max-height 250ms ease-in-out;
    max-height: 0px;
  }
}

@include gt(small) {
  .Toast-Container-enter {
    transform: translateX(-100%);
  }

  .Toast-Container-enter-active {
    transition: transform 250ms ease-in-out;
    transform: translateX(0%);
  }

  .Toast-Container-exit {
    transform: translateX(0%);
  }

  .Toast-Container-exit-active {
    transition: transform 250ms ease-in-out;
    transform: translateX(-100%);
  }
}

.Toast {
  width: fit-content;
  min-width: 250px;
  position: relative;
  box-shadow: $box-shadow-medium;

  transition: rotate 0.4s ease-out;

  &:hover .Toast-closeButton {
    opacity: 1;
  }
}

.Toast-closeButton {
  opacity: 0;
  position: absolute;
  right: $spacing--base * 2;
  top: $spacing--base * 2;
  z-index: 1;
  cursor: pointer;
  appearance: none;
  background: transparent;
  outline: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background 100ms ease-in,
    opacity 100ms ease-in;

  background-color: $palette--tint-2-grey;
  border: 1px solid $palette--tint-3-grey;
  box-sizing: border-box;
  border-radius: 25px;

  &:hover {
    background-color: $palette--tint-3-grey;
  }
}

.Toast-content {
  font-size: $font-size--f;
  color: $palette--dark-grey;
  line-height: 21px;
}

.Toast-title {
  font-weight: $font-weight--bold;
  font-size: $font-size--e;
  color: $color-2019--font-primary;
  line-height: 24px;
  padding-right: $spacing--medium;
}

.Toast-buttonWrapper {
  display: inline-block;
  margin-top: $spacing--small;
}
